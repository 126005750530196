import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap-modal';
import ReactRouterPropTypes from 'react-router-prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

import { requestEventsDetail, submitRegistrationRequest } from 'store/actions/events/detail';
import {
  requestCurrentUser,
  addEventToCart,
  removeEventFromCart,
} from 'store/actions/accounts/user';
import { eventExplorePropTypes, userPropTypes } from 'helpers/proptypes';
import { renderCalendar } from 'helpers/calendar';
import { EventDictionary } from 'helpers/dictionaries';
import { isRegistrationOpen } from 'helpers/utils';

import { Alert, Calendar, Loading } from 'components';
import {
  DraftEventInfoAlert,
  EventCreditPanel,
  EventInfoPanel,
  EventRidInfoPanel,
  EventRegistrationPanel,
  RegistrationRequestForm,
  EventHeader,
} from './components';

const { PUB_STATUS, REG_STATUS } = EventDictionary;

export class EventDetail extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    userLoading: PropTypes.bool.isRequired,
    eventLoading: PropTypes.bool.isRequired,
    userErrored: PropTypes.bool.isRequired,
    eventErrored: PropTypes.bool.isRequired,
    eventError: PropTypes.object,
    event: eventExplorePropTypes,
    user: userPropTypes,
    fetchEventData: PropTypes.func.isRequired,
    fetchUserData: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired,
    submitRegistrationRequest: PropTypes.func.isRequired,
  };

  state = {
    notificationModalOpen: false,
    registrationRequestModalOpen: false,
  };

  handleAddCartClick = () => {
    this.props.addToCart(this.props.event.pk).then(() => {
      window.show_stack_topleft('Added', 'The event was added to your cart.', 'success');
      $('#cart').text(this.props.user.shoppingcart.events.length);
      var notice = window.localStorage.getItem('registration-notice') === 'true';
      if (notice) return;
      this.openNotificationModal();
      window.localStorage.setItem('registration-notice', true);
    });
  };

  handleRemoveCartClick = () => {
    this.props.removeFromCart(this.props.event.pk).then(() => {
      window.show_stack_topleft('Removed', 'The event was removed from your cart.', 'success');
      $('#cart').text(this.props.user.shoppingcart.events.length);
    });
  };

  handleRegistrationRequestClick = () => {
    this.openRegistrationRequestModal();
  };

  handleRegistrationRequestSubmit = (data) => {
    this.closeRegistrationRequestModal();
    data = {
      ...data,
      event: this.props.event.pk,
      user: this.props.user.pk,
      status: REG_STATUS.PENDING,
    };
    this.props.submitRegistrationRequest(data).then(() => {
      window.show_stack_topleft('Sent', 'Your registration request has been sent.', 'success');
    });
  };

  openNotificationModal = () => this.setState({ notificationModalOpen: true });
  closeNotificationModal = () => this.setState({ notificationModalOpen: false });
  openRegistrationRequestModal = () => this.setState({ registrationRequestModalOpen: true });
  closeRegistrationRequestModal = () => this.setState({ registrationRequestModalOpen: false });

  componentDidMount() {
    this.props.fetchEventData(this.props.match.params.eventId);
    this.props.fetchUserData();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
    renderCalendar();
  }

  render() {
    if (
      this.props.eventLoading ||
      this.props.userLoading ||
      (this.props.match.params.eventId &&
        this.props.event &&
        parseInt(this.props.match.params.eventId) !== this.props.event.pk)
    ) {
      return <Loading />;
    }

    const params = queryString.parse(window.location.search);
    const isAnon = Boolean(params.token && this.props.userErrored);

    if ((this.props.eventErrored || this.props.userErrored) && !isAnon) {
      if (get(this.props.eventError, 'response.status') === 404) {
        return (
          <Alert type="warning" icon="fa-question-circle">
            <React.Fragment>
              Event not found.{' '}
              <Link
                to={window.URLS['pd:event_explore']()}
                className="btn btn-default btn-sm icon-space-l"
              >
                Explore Events
              </Link>
            </React.Fragment>
          </Alert>
        );
      }
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Could not retrieve event data.
        </Alert>
      );
    }

    const isFacilitator =
      this.props.user &&
      this.props.user.isFacilitator &&
      this.props.event.facilitators.includes(this.props.user.pk);

    if (this.props.event.publication_status === PUB_STATUS.DRAFT) {
      if (!isAnon && !this.props.user.isAdmin && !this.props.user.isManager && !isFacilitator) {
        return <Redirect to="/404" />;
      }
    }

    const registrationOpen = isRegistrationOpen(
      this.props.event.registration_start,
      this.props.event.registration_end
    );
    const addDropOpen = isRegistrationOpen(
      this.props.event.add_drop_start,
      this.props.event.add_drop_end
    );

    const calendarTitle = `Personal Schedule`;
    const tooltipMessage =
      'Displayed are events currently enrolled in,<br /> registration pending requests, and events<br /> in your shopping cart to show your current schedule';
    return (
      <div className="col-xs-12">
        <div className="row">
          <DraftEventInfoAlert
            publicationStatus={this.props.event.publication_status}
            facilitatorRequestingCeus={this.props.event.facilitator_requesting_ceus}
            facilitatorRidNumberCreated={!!this.props.event.facilitator_rid_activity_number_created}
          />
        </div>
        <div className="row">
          <EventHeader
            event={this.props.event}
            user={this.props.user}
            addDropOpen={addDropOpen}
            registrationOpen={registrationOpen}
            onAddCartClick={this.handleAddCartClick}
            onRemoveCartClick={this.handleRemoveCartClick}
            onRegistrationRequestClick={this.handleRegistrationRequestClick}
          />
        </div>
        <div className="row">
          <EventInfoPanel teaserOpen={isAnon} event={this.props.event} />
          <EventRegistrationPanel
            event={this.props.event}
            addDropOpen={addDropOpen}
            registrationOpen={registrationOpen}
          />
          <EventCreditPanel event={this.props.event} credit={this.props.event.credit_approval} />

          {!isAnon && <EventRidInfoPanel event={this.props.event} user={this.props.user} />}
        </div>
        {!isAnon && (
          <div className="row">
            <Calendar
              id="calendar"
              eventId={this.props.event.pk}
              calendarType="cart"
              tooltipMessage={tooltipMessage}
              calendarTitle={calendarTitle}
            />
          </div>
        )}
        <ReactTooltip type="custom" effect="solid" className="events__tooltip" multiline />
        <Modal
          show={this.state.notificationModalOpen}
          onHide={this.closeNotificationModal}
          aria-labelledby="notificationModalLabel"
          modalPrefix="bootstrap-modal modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="notificationModalLabel">Complete Registration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              To complete the registration process, you must go to your cart (found in the
              upper-right corner of the navigation bar). You may continue to add events to your cart
              and checkout when you are ready.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-info" onClick={this.closeNotificationModal}>
              OK
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.registrationRequestModalOpen}
          onHide={this.closeRegistrationRequestModal}
          aria-labelledby="registrationRequestModalLabel"
          modalPrefix="bootstrap-modal modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="registrationRequestModalLabel">Registration Request Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please fill out the reason you would like to participate in this event. Processing of
              Add Drop Period requests will happen following the end of the add drop period (
              <strong>
                {moment(this.props.event.add_drop_end).format('ddd, MMMM Do YYYY, h:mm a')}
              </strong>
              ). If your manager approves, and there is available capacity, you will be added into
              this event.
            </p>
            <RegistrationRequestForm
              onSubmit={this.handleRegistrationRequestSubmit}
              close={this.closeRegistrationRequestModal}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ accountsUser, eventsDetail }) => {
  return {
    userLoading: accountsUser.isLoading,
    eventLoading: eventsDetail.isLoading,
    userErrored: accountsUser.hasErrored,
    eventErrored: eventsDetail.hasErrored,
    eventError: eventsDetail.error,
    user: accountsUser[accountsUser.current],
    event: eventsDetail.event,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEventData: (eventId) => dispatch(requestEventsDetail(eventId)),
    fetchUserData: () => dispatch(requestCurrentUser()),
    addToCart: (eventId) => dispatch(addEventToCart(eventId)),
    removeFromCart: (eventId) => dispatch(removeEventFromCart(eventId)),
    submitRegistrationRequest: (data) => dispatch(submitRegistrationRequest(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventDetail);
